<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card card-warning ">
                <div class="card-header ¡">
                    <h3 class="card-title">{{ $t('general.asignacionequipos') }}</h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="input-group">
                                <select class="form-control" v-model="Seleccionado">
                                    <option v-for="nombres in tipos_equipo" :value="nombres.id_equipo" :key="nombres.id_equipo"> {{ nombres.nombre_equipo }} </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <button class="btn btn-primary" @click="agregarEquipo(id,Seleccionado)">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-6">
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr v-for="equipo in equipos" :key="equipo.id_equipo_especialidad">
                                        <td v-if="equipo">{{ equipo.equipos_id_equipo }}</td>
                                        <td v-if="equipo"><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarEquipo(equipo.id,equipo.equipos_id_equipo)">{{ $t('general.eliminar') }}</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</template>
<script>
import $ from 'jquery';
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id', 'especialidad'],
    data() {
        return {
            tipos_equipo: [],
            Seleccionado: 163,
            equipos: [],
        }
    },
    watch: {
        especialidad() {
            this.cargardatos();
            this.cargarmasdatos();
        },
    },
    methods: {
        async cargardatos() {
            /* get  modulos/pwgsapi/index.php/tipos-equipo */
            const api = new PwgsApi;
            const datos = await api.get('tipos-equipo');
            this.tipos_equipo = datos.datos;
        },
        async cargarmasdatos() {
            /* get  modulos/pwgsapi/index.php/especialidades/:id/equipos */
            const api = new PwgsApi;
            const datos = await api.get('especialidades/' + this.id + '/equipos');
            this.equipos = datos.datos;
        },
        async eliminarEquipo(id, idequipo) {
            /* delete  modulos/pwgsapi/index.php/especialidades/:id/equipo/:id_equipo */
            const api = new PwgsApi;
            await api.delete('especialidades/' + id + '/equipo/' + idequipo);
            this.cargarmasdatos();
        },
        async agregarEquipo(id,idequipo) {
            /* post   modulos/pwgsapi/index.php/especialidades/:id/equipo/:id_equipo */
            let repetido = 0;
           /* for (let equipo in this.equipos) {
                console.log("equipo2", equipo.equipos_id_equipo);
                if (idequipo == equipo.equipos_id_equipo) { 
                       console.log("REPETIDO", this.Seleccionado);
                    repetido++;
                }
            }*/
            if (repetido < 1) {
                const api = new PwgsApi;
                await api.post('especialidades/' + id + '/equipo/' + idequipo);
                this.cargarmasdatos();
            }
        }
    },
    mounted() {
        const $select = $('.select2.especialidades-guardias');
        $select.select2({
            theme: 'bootstrap4'
        });
        this.cargardatos();
        this.cargarmasdatos();
    },
    }
</script>

